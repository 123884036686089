export const TRANSACTION_VARIANT = {
    flatSell: 'FLAT_SELL',
    flatRent: 'FLAT_RENT',
    studioFlatSell: 'STUDIO_FLAT_SELL',
    studioFlatRent: 'STUDIO_FLAT_RENT',
    houseSell: 'HOUSE_SELL',
    houseRent: 'HOUSE_RENT',
    investment: 'INVESTMENT',
    roomRent: 'ROOM_RENT',
    terrainSell: 'TERRAIN_SELL',
    terrainRent: 'TERRAIN_RENT',
    commercialPropertySell: 'COMMERCIAL_PROPERTY_SELL',
    commercialPropertyRent: 'COMMERCIAL_PROPERTY_RENT',
    hallSell: 'HALL_SELL',
    hallRent: 'HALL_RENT',
    garageSell: 'GARAGE_SELL',
    garageRent: 'GARAGE_RENT',
} as const;

export type TransactionVariant = ObjectValues<typeof TRANSACTION_VARIANT>;
