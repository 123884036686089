import { SEARCH_BOX_FIELDS_LAYOUT, SEARCH_BOX_ROW_LAYOUT } from '@config/consts/searchBox/layout/search/otodompl';
import type { SearchConfig } from '@config/search/type';

export const SEARCH_CONFIG: SearchConfig = {
    isSaveSearchClicktipEnabled: true,
    searchBoxRows: SEARCH_BOX_ROW_LAYOUT,
    searchBoxFieldsLayout: SEARCH_BOX_FIELDS_LAYOUT,
    areLocationObjectBreadcrumbsEnabled: true,
    seo: {
        studioFlatLabels: ['kawalerka', 'kawalerki'],
        metaDataVariants: ['primaryMarket', 'secondaryMarket'],
        variantForDescriptionOfNumberOfRooms: 'word',
        isCrawlerTextTransformationEnabled: true,
        internalSeoLinks: {
            propertiesForSell: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'TERRAIN',
                'COMMERCIAL_PROPERTY',
                'OFFICE',
                'HALL',
                'GARAGE',
                'VILLAS',
                'PENTHOUSES',
            ],
            propertiesForRent: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'ROOM',
                'TERRAIN',
                'COMMERCIAL_PROPERTY',
                'OFFICE',
                'HALL',
                'GARAGE',
                'VILLAS',
                'PENTHOUSES',
            ],
            propertiesFromPrimaryMarket: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'INVESTMENT',
                'COMMERCIAL_PROPERTY',
                'HALL',
                'GARAGE',
            ],
            estatesWithRelatedNumberOfRoomsSearches: ['FLAT', 'HOUSE'],
        },
        queryParametersEnabledForIndexing: ['page', 'description'],
    },
    defaultBoundingBoxForMap: {
        neLat: 54.85131525968606,
        neLng: 24.14794921875,
        swLat: 48.96579381461063,
        swLng: 13.974609375,
    },
    vectorMapId: '125da5192c84655c',
    locationLevelLikeCity: ['city'],
    locationLevelLikeDistrictAndSubdistrict: ['district', 'residential'],
    locationLevelLikeDistrict: ['district'],
    locationLevelLikeSubdistrict: ['residential'],
    shouldShowSearchCriteriaOnSaveSearchModal: true,
    isPermanentRedirectRegexp: /\/rynek-pierwotny|\/rynek-wtorny/gi,
    shouldShowSaveSearchPrompt: true,
    isSpecialOfferForUnitsExperimentEnabled: false,
    isPopularSearchesSectionEnabled: true,
};
