export const SEARCH_FORM_UNIVERSAL_FIELD = {
    advertId: 'advertId',
    area: 'area',
    areaMax: 'areaMax',
    areaMin: 'areaMin',
    buildingMaterial: 'buildingMaterial',
    buildingType: 'buildingType',
    buildYear: 'buildYear',
    buildYearMax: 'buildYearMax',
    buildYearMin: 'buildYearMin',
    commodity: 'commodity',
    condition: 'condition',
    daysSinceCreated: 'daysSinceCreated',
    description: 'description',
    developerName: 'developerName',
    distanceRadius: 'distanceRadius',
    divisioningType: 'divisioningType',
    estate: 'estate',
    extras: 'extras',
    floors: 'floors',
    floorsNumber: 'floorsNumber',
    floorsNumberMax: 'floorsNumberMax',
    floorsNumberMin: 'floorsNumberMin',
    freeFrom: 'freeFrom',
    hasDiscount: 'hasDiscount',
    hasMovie: 'hasMovie',
    hasOpenDay: 'hasOpenDay',
    hasPhotos: 'hasPhotos',
    hasRemoteServices: 'hasRemoteServices',
    hasWalkaround: 'hasWalkaround',
    heating: 'heating',
    height: 'height',
    heightMax: 'heightMax',
    heightMin: 'heightMin',
    infrastructure: 'infrastructure',
    investmentEstateType: 'investmentEstateType',
    investmentName: 'investmentName',
    investmentState: 'investmentState',
    isBungalov: 'isBungalov',
    isExclusiveOffer: 'isExclusiveOffer',
    isForStudents: 'isForStudents',
    isPrivateOwner: 'isPrivateOwner',
    isRecreational: 'isRecreational',
    location: 'location',
    locationPicker: 'locationPicker',
    locations: 'locations',
    placeId: 'placeId',
    main: 'main',
    market: 'market',
    media: 'media',
    ownerTypeSingleSelect: 'ownerTypeSingleSelect',
    peoplePerRoom: 'peoplePerRoom',
    plotType: 'plotType',
    price: 'price',
    priceMax: 'priceMax',
    priceMin: 'priceMin',
    pricePerMeter: 'pricePerMeter',
    pricePerMeterMax: 'pricePerMeterMax',
    pricePerMeterMin: 'pricePerMeterMin',
    roofType: 'roofType',
    roomsNumber: 'roomsNumber',
    security: 'security',
    structure: 'structure',
    submit: 'submit',
    surroundings: 'surroundings',
    terrainArea: 'terrainArea',
    terrainAreaMax: 'terrainAreaMax',
    terrainAreaMin: 'terrainAreaMin',
    transaction: 'transaction',
    useTypes: 'useTypes',
    vicinity: 'vicinity',
    wcCount: 'wcCount',
} as const;

export type SearchFormUniversalFieldName = ObjectValues<typeof SEARCH_FORM_UNIVERSAL_FIELD>;
